<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M10.5 6.66085V0.999848H1.125C0.843251 0.98345 0.566514 1.07956 0.355576 1.26706C0.144637 1.45456 0.0167483 1.71812 0 1.99985L0 21.3118C0.0167483 21.5936 0.144637 21.8571 0.355576 22.0446C0.566514 22.2321 0.843251 22.3282 1.125 22.3118H16.875C17.1567 22.3282 17.4335 22.2321 17.6444 22.0446C17.8554 21.8571 17.9833 21.5936 18 21.3118V7.65985H11.625C11.3438 7.67466 11.0681 7.57813 10.8575 7.39116C10.647 7.20419 10.5185 6.94182 10.5 6.66085ZM18 6.07385V6.32785H12V0.999848H12.286C12.5793 0.995823 12.8639 1.09934 13.086 1.29085L17.672 5.36985C17.7735 5.45717 17.8553 5.56512 17.9118 5.68651C17.9684 5.8079 17.9984 5.93994 18 6.07385Z"
        :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
